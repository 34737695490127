import { Link, useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../components/layout';
import Button from 'react-bootstrap/Button';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import LocalBusinessSchema from '../components/schema/localBusinessSchema';
import { Helmet } from 'react-helmet';
import ImageSection from '../components/image-section';
import ServiceHero from '../components/service-hero';
import ServiceHeroMobile from '../components/service-hero-mobile';
import SVGAU1 from '../components/svg/au1-svg';
import SVGBP2 from '../components/svg/bp2-svg';
import SVGAU2 from '../components/svg/au2-svg';
import HTMLReactParser from 'html-react-parser';

const AboutUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "About-Us-Hero-AUP" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      CHImg: wpMediaItem(title: { eq: "Company-History-Image-AUP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      AAAImg: wpMediaItem(title: { eq: "A-Bit_About-Alex-Image-AUP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      TeamImg: wpMediaItem(title: { eq: "Team-Image" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      aboutUs: wpAboutUs {
        aboutUsFields {
          aBitAboutAlex {
            body
            title
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          companyHistory {
            body
            title
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          heroSection {
            btnText
            heading1
            heading1Body
            subheading
            title
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          lookOutForTheColstonTeam {
            body
            title
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
  const CHImg = data.CHImg?.localFile.childImageSharp.gatsbyImageData;
  const AAAImg = data.AAAImg?.localFile.childImageSharp.gatsbyImageData;
  const TeamImg = data.TeamImg?.localFile.childImageSharp.gatsbyImageData;
  const AboutUs = data.aboutUs?.aboutUsFields;
  console.log(AboutUs);

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: '1',
        name: 'Home',
        item: {
          id: `${siteUrl}`,
          url: `${siteUrl}`,
        },
      },
      {
        '@type': 'ListItem',
        position: '2',
        name: 'About Us',
        item: {
          id: `${siteUrl}/about-us`,
          url: `${siteUrl}/about-us`,
        },
      },
    ],
  };

  return (
    <Layout>
      <div className='position-relative'>
        <Helmet>
          {' '}
          <script type='application/ld+json'>
            {JSON.stringify(breadcrumb)}
          </script>
        </Helmet>
        <GatsbySeo
          title='About Us | Colston Ltd'
          description='Colston is proud to be a dependable local modern general building company that can provide all trades contracting work.'
          language='en'
          openGraph={{
            type: 'website',
            url: `${siteUrl}/about-us`,
            title: 'About Us | Colston Ltd',
            description:
              'Colston is proud to be a dependable local modern general building company that can provide all trades contracting work.',
            images: [
              {
                url: `${data.heroImg?.localFile.publicURL}`,
                width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
                height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
                alt: `${data.heroImg?.altText}`,
              },
            ],
          }}
        />
        <SVGAU1
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block top-10 start-0'
        />
        <SVGAU2
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block bottom-0 start-0'
        />
        <ServiceHero
          title={AboutUs.heroSection.title}
          subTitle={AboutUs.heroSection.heading1}
          description={HTMLReactParser(AboutUs.heroSection.heading1Body)}
          image={
            AboutUs.heroSection?.image.node?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          imageAlt={AboutUs.heroSection?.image?.node?.altText}
          ctaText={AboutUs.heroSection.subheading}
          btnText={AboutUs.heroSection.btnText}
        />
        <ServiceHeroMobile
          title={AboutUs.heroSection.title}
          subTitle={AboutUs.heroSection.heading1}
          description={HTMLReactParser(AboutUs.heroSection.heading1Body)}
          ctaText={AboutUs.heroSection.subheading}
          btnText={AboutUs.heroSection.btnText}
        />
        <section className='pb-xl-8 pt-xl-5 pt-10'>
          <ImageSection
            title={AboutUs.companyHistory.title}
            image={
              AboutUs.companyHistory?.image.node?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            imageAlt={AboutUs.companyHistory?.image?.node?.altText}
            text={HTMLReactParser(AboutUs.companyHistory.body)}
            imgSpan={6}
            textSpan={6}
            textPos='top-50 translate-middle-y end--20'
            rounded='right-rounded'
            imgWidth='100%'
            imgHeight='43rem'
            order='last'
          />
        </section>
        <section className='py-xl-8 pt-5'>
          <ImageSection
            title={AboutUs.lookOutForTheColstonTeam.title}
            image={
              AboutUs.lookOutForTheColstonTeam?.image.node?.localFile
                ?.childImageSharp?.gatsbyImageData
            }
            imageAlt={AboutUs.lookOutForTheColstonTeam?.image?.node?.altText}
            text={HTMLReactParser(AboutUs.lookOutForTheColstonTeam.body)}
            imgSpan={6}
            textSpan={6}
            textPos='top-50 translate-middle-y  start--20'
            rounded='left-rounded'
            imgWidth='100%'
            imgHeight='35rem'
          />
        </section>
        <section className='py-xl-8 py-5'>
          <ImageSection
            title={AboutUs.aBitAboutAlex.title}
            image={
              AboutUs.aBitAboutAlex?.image.node?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            imageAlt={AboutUs.aBitAboutAlex?.image?.node?.altText}
            text={HTMLReactParser(AboutUs.aBitAboutAlex.body)}
            imgWidth='100%'
            imgSpan={6}
            textSpan={6}
            imgHeight='40rem'
            rounded='right-rounded'
            textPos='top-50 translate-middle-y end--20'
            order='last'
          />
        </section>
      </div>
    </Layout>
  );
};

export default AboutUsPage;
